import * as actionTypes from "../actionTypes";

// Home
export const getDepartments = (data) => {
    return {
        type: actionTypes.GET_DEPARTMENTS_START,
        payload: data,
    };
};
export const getDepartmentsSuccess = (data) => {
    return {
        type: actionTypes.GET_DEPARTMENTS_SUCCESS,
        payload: data,
    };
};
export const getDepartmentsFailure = (data) => {
    return {
        type: actionTypes.GET_DEPARTMENTS_FAILURE,
        payload: data,
    };
};

export const getTechnologies = (data) => {
    return {
        type: actionTypes.GET_TECHNOLOGIES_START,
        payload: data,
    };
};
export const getTechnologiesSuccess = (data) => {
    return {
        type: actionTypes.GET_TECHNOLOGIES_SUCCESS,
        payload: data,
    };
};
export const getTechnologiesFailure = (data) => {
    return {
        type: actionTypes.GET_TECHNOLOGIES_FAILURE,
        payload: data,
    };
};

export const getEmployee = (data) => {
    return {
        type: actionTypes.GET_EMPLOYEE_START,
        payload: data,
    };
};
export const getEmployeeSuccess = (data) => {
    return {
        type: actionTypes.GET_EMPLOYEE_SUCCESS,
        payload: data,
    };
};
export const getEmployeeFailure = (data) => {
    return {
        type: actionTypes.GET_EMPLOYEE_FAILURE,
        payload: data,
    };
};

export const submitContactUs = (data) => {
    return {
        type: actionTypes.SUBMIT_CONTACT_US_START,
        payload: data,
    };
};
export const submitContactUsSuccess = (data) => {
    return {
        type: actionTypes.SUBMIT_CONTACT_US_SUCCESS,
        payload: data,
    };
};
export const submitContactUsFailure = (data) => {
    return {
        type: actionTypes.SUBMIT_CONTACT_US_FAILURE,
        payload: data,
    };
};

// Technology
export const getTechnologyById = (data) => {
    return {
        type: actionTypes.GET_TECHNOLOGY_BY_ID_START,
        payload: data,
    };
};
export const getTechnologyByIdSuccess = (data) => {
    return {
        type: actionTypes.GET_TECHNOLOGY_BY_ID_SUCCESS,
        payload: data,
    };
};
export const getTechnologyByIdFailure = (data) => {
    return {
        type: actionTypes.GET_TECHNOLOGY_BY_ID_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};
