import HttpService from "./http.service";

class GeneralService extends HttpService {
    getDepartments = () => this.get("department");

    getTechnologies = () => this.get("technology");

    getEmployee = () => this.get("employee");

    getTechById = (params) => this.getById("technology", params);

    contactUs = (params) => this.post("contactUs",params);
}

export default new GeneralService();