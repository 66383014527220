import MenuItems from './MenuItems.js';
import { APP_DOMAIN_PREFIX } from '../../../environment/index.js';
import '../scss/dropdown.scss';
import { notebook, feedback, html, videoPlayer, profits, lineChart, monitor } from "../../../assets/images/images";

const menuItems = [
    // {
    //     title: 'Company',
    //     url: '/',
    //     submenu: [
    //         {
    //             title: 'About Us',
    //             url: '/',
    //         },
    //         {
    //             title: 'Hire Us',
    //             url: '/',
    //         },
    //         {
    //             title: 'Contact Us',
    //             url: '/contact-us',
    //         },
    //         {
    //             title: 'Talent',
    //             url: '/',
    //         }
    //     ]
    // },
    //  {
    //     title: 'Hire Talent',
    //     url: '/',
    //     submenu: [
    //         {
    //             icon: monitor,
    //             title: 'Design & Creatives',
    //             url: '/hiring',
    //         },
    //         {
    //             icon: feedback,
    //             title: 'Digital Marketing',
    //             url: '/',
    //         },
    //         {
    //             icon: html,
    //             title: 'Development & IT',
    //             url: '/',
    //         },
    //         {
    //             icon: profits,
    //             title: 'Finance & Accounting',
    //             url: '/',
    //         },
    //         {
    //             icon: lineChart,
    //             title: 'Business Analyst',
    //             url: '/',
    //         },
    //         {
    //             icon: videoPlayer,
    //             title: 'Video & Animation',
    //             url: '/',
    //         },
    //         {
    //             icon: notebook,
    //             title: 'Writing & Translation',
    //             url: '/',
    //         }
    //     ]
    // }
];

const Navbar = ({ setOpenHireTalent, setOpenMvp }) => {
    return (
        <nav
            onMouseEnter={() => { setOpenMvp(false); setOpenHireTalent(false) }}
        >
            <ul className="menus">
                {
                    menuItems.map((menu, index) => {
                        const depthlevel = 0;
                        return <MenuItems items={menu} key={index} depthlevel={depthlevel} />
                    })
                }
            </ul>
        </nav>
    )
}

export default Navbar;