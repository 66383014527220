import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"; 
import FrontLayoutRoutes from "./frontend/frontRoutes"; 
// import {Confirmation} from "../components";
import { useEffect } from 'react';

  
const Home =  React.lazy(() => import('../container/frontend/home'));
const Product =  React.lazy(() => import('../container/frontend/Product'));
const ContactUs =  React.lazy(() => import('../container/frontend/contactus'));
const Talent =  React.lazy(() => import('../container/frontend/talent'));
const Hiring =  React.lazy(() => import('../container/frontend/hiring'));

function Routes() {

  // useEffect(() => { //redirect to home page when page is refresh
  //   var url = document.URL.split("/"); 
  //   const isRefreshed = window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD;
  //   if (isRefreshed) {
  //     let domLink=url[0]+"//"+url[2]; 
  //     window.location.replace(domLink);
  //   }
  // }, []);

  return (
    <Router>
      <Switch>
        <FrontLayoutRoutes exact path="/"  routeName={'home'} render={matchprops => (<Home {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/product/:slug"  routeName={'product'} render={matchprops => (<Product {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/contact-us"  routeName={'contactus'} render={matchprops => (<ContactUs {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/talent"  routeName={'talent'} render={matchprops => (<Talent {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/hiring/:slug"  routeName={'hiring'} render={matchprops => (<Hiring {...matchprops} />)} />
      </Switch>
    </Router>
  )
    ;
}

export default Routes;