import React, { useEffect, useState, Fragment } from "react";
import {
  AppBar, Toolbar, Box, Button, Container, Grid, Menu, Drawer, Modal,
  Backdrop, Fade, Typography, ListItem, ListItemText
} from '@material-ui/core';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { useStyles } from "./styles";
import { generalStyles } from "../general/general";
import { mobilosLogo, mobilosLogoMobile, notebook, feedback, html, videoPlayer, profits, lineChart, monitor, Vidly, tryp, Ropay, VidlyMobile, trypMobile, RopayMobile, developersIcons, webDeveloperIcons, mobileAppDeveloperIcon, qualityAssuranceIcon, gameDeveloperIcon, creativeDesignIcon, marketingIcon } from "../../../assets/images/images";
import Navbar from './Navbar.js';
import "../scss/header.scss";
import '../scss/dropdown.scss';
import { Link as RouterLink, useLocation, withRouter } from 'react-router-dom';
import { getUserDataFromLocalStorage } from "../../../services/utils";
import { connect } from "react-redux";
import { AnimatedIcon } from "../../../components";

function Header(props) {
  let userDetail = getUserDataFromLocalStorage();
  const [openMvp, setOpenMvp] = useState(false);
  const [openHireTalent, setOpenHireTalent] = useState(false);

  const classes = useStyles();
  const generalClasses = generalStyles();

  const location = useLocation().pathname;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setOpenMvpMobile(false);
  };
  const toggleDrawerHireTalent = () => {
    setDrawerOpen(!drawerOpen);
    setOpenHireTalent(false);
  };

  const mobileLogoClick = () => {
    props.history.push('/');
    toggleDrawer();
  }

  const handleMVPClick = (isMobile, url, slug) => {
    if (isMobile) {
      toggleDrawer();
    } else {
      setOpenMvp(false);
    }
    props.history.push({ pathname: `/product/${slug}`, state: { url: url } });
  }

  const handleHireTalentClick = (slug, id) => {
    if (id) {
      setOpenHireTalent(false);
      props.history.push({ pathname: `/hiring/${slug}`, state: { id: id } });
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      const clickedButton = event.target.closest('.mvp-button');
      const clickedInside = event.target.closest('.mvp-subbox');
      if (!clickedButton && !clickedInside) {
        setOpenMvp(false);
      }
      const clickedButtonHire = event.target.closest('.mvp-button-hire');
      const clickedInsideHire = event.target.closest('.mvp-subbox-hire');
      if (!clickedButtonHire && !clickedInsideHire) {
        setOpenHireTalent(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setOpenHireTalent(false);
    setOpenMvp(false);
  }, [props.location.pathname]);

  const menuItems = [
    // {
    //   title: 'Company',
    //   url: '/',
    //   submenu: [
    //     {
    //       title: 'About Us',
    //       url: '/',
    //     },
    //     {
    //       title: 'Hire Us',
    //       url: '/',
    //     },
    //     {
    //       title: 'Contact Us',
    //       url: '/contact-us',
    //     },
    //     {
    //       title: 'Talent',
    //       url: '/',
    //     }
    //   ]
    // },
    {
      title: 'Hire Talent',
      url: '/',
      submenu: [
        {
          title: 'Web Developers',
          url: '/',
          submenu: [
            {
              title: 'Java Developers',
              url: '/hiring/java-developers',
              id: '6482d5fa8cb990e9bb3889b8'
            },
            {
              title: 'PHP/Laravel Developers',
              url: '/hiring/php-laravel-developers',
              id: '6482d5fa8cb990e9bb3889ba'
            },
            {
              title: 'React.js/Vue.js Developers',
              url: '/hiring/reactjs-vuejs-developers',
              id: '6482d5fa8cb990e9bb3889bc'
            },
            {
              title: 'Node.js Developers',
              url: '/hiring/nodejs-developers',
              id: '6482d5fa8cb990e9bb3889be'
            },
            {
              title: 'Angular Developers',
              url: '/hiring/angular-developers',
              id: '6482d5fa8cb990e9bb3889c0'
            },
          ]
        },
        {
          title: 'Mobile App Developers',
          url: '/',
          submenu: [
            {
              title: 'Android Developers',
              url: '/hiring/android-developers',
              id: '6482d5fa8cb990e9bb3889c2'
            },
            {
              title: 'iOS Developers',
              url: '/hiring/ios-developers',
              id: '6482d5fa8cb990e9bb3889c4'
            },
          ]
        },
        {
          title: 'Quality Assurance',
          url: '/',
          submenu: [
            {
              title: 'QA Engineers',
              url: '/hiring/qa-engineer',
              id: '6482d5fa8cb990e9bb3889cc'
            },
          ]
        },
        {
          title: 'Game Development Team',
          url: '/',
          submenu: [
            {
              title: 'Game Engineers',
              url: '/hiring/game-engineer',
              id: '6482f3fa9ca340906a8e98ca'
            },
          ]
        },
        {
          title: 'Creatives & Design',
          url: '/',
          submenu: [
            {
              title: 'UI/UX Engineers',
              url: '/hiring/ui-ux-engineer',
              id: '6482d5fa8cb990e9bb3889c6'
            },
            {
              title: 'Graphic Designer',
              url: '/hiring/graphic-designer',
              id: '6482d5fa8cb990e9bb3889c8'
            },
            {
              title: 'Video Animators',
              url: '/hiring/video-animators',
              id: '6482d5fa8cb990e9bb3889ca'
            },
          ]
        },
        {
          title: 'Marketing',
          url: '/',
          submenu: [
            {
              title: 'Social Media Marketing Experts',
              url: '/hiring/social-media-marketing-experts',
              id: '6482d5fa8cb990e9bb3889ce'
            },
            {
              title: 'SEO Experts',
              url: '/hiring/seo-experts',
              id: '6482d5fa8cb990e9bb3889d0'
            },
            {
              title: 'PPC/Media Buying Experts',
              url: '/hiring/ppc-media-buying-experts',
              id: '6482d5fa8cb990e9bb3889d2'
            },
            {
              title: 'Copywriting/Content Writers',
              url: '/hiring/copywriting-content-writers',
              id: '6482d5fa8cb990e9bb3889d4'
            },
          ]
        },

      ]
    },

  ]


  const [openMvpMobile, setOpenMvpMobile] = useState(false);

  const handleClickMvp = () => {
    setOpenMvpMobile((prev) => !prev);
  };

  const SingleLevel = ({ item, depthLevel }) => {
    console.log('single level item ', item);
    return (
      <ListItem className="client-padding-0" style={{ whiteSpace: 'nowrap' }} button>
        <RouterLink to={{ pathname: item.url, state: { id: item.id ? item.id : '' } }} style={{ paddingLeft: depthLevel * 10, textDecoration: 'none' }} className='sidebar-list-text' >
          {item.icon && <span className="animated-icon"><AnimatedIcon icon={item.icon} height={23} width={23} /></span>}
          <ListItemText onClick={toggleDrawer} primary={item.title} />
        </RouterLink>
      </ListItem>
    );
  };

  const MultiLevel = ({ item, depthLevel }) => {
    const { submenu: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    return (
      <Fragment>
        <ListItem className="client-padding-0" button onClick={handleClick}>
          <ListItemText primary={item.title} className='sidebar-list-text' />
          {open ? <ExpandLess style={{ color: '#1A8FE3' }} /> : <NavigateNext style={{ color: '#1A8FE3' }} />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit className='sidebar-collapse' style={{ paddingLeft: '20px' }}>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItem key={key} item={child} depthLevel={depthLevel + 1} />
            ))}
          </List>
        </Collapse>
      </Fragment>
    );
  };

  const MenuItem = ({ item, depthLevel}) => {
    const Component = item.submenu ? MultiLevel : SingleLevel;
    return <Component item={item} depthLevel={depthLevel} />;
  };

  // Change color of header on scrolling

  const listenScrollEvent = e => {
    if (window.scrollY > 0)
      setScrolled(true);
    else
      setScrolled(false);
  }

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  });

  return (
    <div className={generalClasses.root}>
      <AppBar position="fixed" className={`navbar-white-fixed ${scrolled && "header-navbar-subpage-top-fixed"}`}>
        <Toolbar disableGutters>
          <Container fixed>
            <div className='navbar-inner'>
              <div className='navbar-center'>
                <Box sx={{ display: { lg: 'none' } }}>
                  <div>
                    {
                      <Fragment key='left'>
                        {/* <Button style={{ minWidth: "40px", left: "-10px", padding: 0 }} onClick={toggleDrawer}>
                          <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 1.04167C0 0.46875 0.46875 0 1.04167 0H18.75C19.3229 0 19.7917 0.46875 19.7917 1.04167C19.7917 1.61458 19.3229 2.08333 18.75 2.08333H1.04167C0.46875 2.08333 0 1.61458 0 1.04167ZM18.75 16.6667H1.04167C0.46875 16.6667 0 17.1354 0 17.7083C0 18.2812 0.46875 18.75 1.04167 18.75H18.75C19.3229 18.75 19.7917 18.2812 19.7917 17.7083C19.7917 17.1354 19.3229 16.6667 18.75 16.6667ZM23.9583 8.33333H6.25C5.67708 8.33333 5.20833 8.80208 5.20833 9.375C5.20833 9.94792 5.67708 10.4167 6.25 10.4167H23.9583C24.5312 10.4167 25 9.94792 25 9.375C25 8.80208 24.5312 8.33333 23.9583 8.33333Z" fill="#1A8FE3" />
                          </svg>
                        </Button> */}
                        <Drawer
                          className={classes.drawer}
                          anchor='left'
                          open={drawerOpen}
                          onClose={toggleDrawer}
                        >
                          <div className='drawer-logo'>
                            <img src={mobilosLogoMobile} alt="logo" onClick={() => mobileLogoClick()} />
                            <div className="close-button" onClick={toggleDrawer}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                <path d="M6.9146 6.9146C6.85991 6.96928 6.78573 7 6.70839 7C6.63105 7 6.55687 6.96928 6.50218 6.9146L3.5 3.91242L0.497822 6.9146C0.443125 6.96928 0.36895 7 0.29161 7C0.214269 7 0.140094 6.96928 0.0853976 6.9146C0.0307175 6.85991 0 6.78573 0 6.70839C0 6.63105 0.0307175 6.55687 0.0853976 6.50218L3.08758 3.5L0.0853976 0.497822C0.0307175 0.443125 0 0.368951 0 0.29161C0 0.214269 0.0307175 0.140094 0.0853976 0.0853977C0.140094 0.0307178 0.214269 0 0.29161 0C0.36895 0 0.443125 0.0307178 0.497822 0.0853977L3.5 3.08758L6.50218 0.0853977C6.55687 0.0307178 6.63105 0 6.70839 0C6.78573 0 6.85991 0.0307178 6.9146 0.0853977C6.96928 0.140094 7 0.214269 7 0.29161C7 0.368951 6.96928 0.443125 6.9146 0.497822L3.91242 3.5L6.9146 6.50218C6.96928 6.55687 7 6.63105 7 6.70839C7 6.78573 6.96928 6.85991 6.9146 6.9146Z" fill="#1A8FE3" />
                              </svg>
                            </div>
                          </div>
                          <div className='sidebar-drawer'>
                            <ListItem className="client-padding-0" style={{ whiteSpace: 'nowrap' }} button>
                              <RouterLink to={{ pathname: '/' }} style={{ textDecoration: 'none' }} className='sidebar-list-text' >
                                <ListItemText onClick={toggleDrawer} primary="Home" />
                              </RouterLink>
                            </ListItem>
                            <ListItem className="client-padding-0" button onClick={handleClickMvp}>
                              <ListItemText primary="MVP" className='sidebar-list-text' />
                              {openMvpMobile ? <ExpandLess style={{ color: '#1A8FE3' }} /> : <NavigateNext style={{ color: '#1A8FE3' }} />}
                            </ListItem>
                            <Collapse in={openMvpMobile} timeout="auto" unmountOnExit className='sidebar-collapse'>
                              <Container className="mvp-sub-container">
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={12} lg={4} >
                                    <div className="mvp-dropdown-item-box-mob" onClick={() => handleMVPClick(true, 'https://rockvale.sa/vidly-mobilos/index.php', 'ott-streaming-platform')}>
                                      <span className="header-icon-disc">
                                        <img src={VidlyMobile} />
                                      </span>
                                      <span>OTT Streaming Platform.</span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={4}>
                                    <div className="mvp-dropdown-item-box-mob" onClick={() => handleMVPClick(true, 'https://rockvale.sa/mobilos-tryppk/index.php', 'fleet-management-system')}>
                                      <span className="header-icon-disc">
                                        <img src={trypMobile} />
                                      </span>
                                      <span>Fleet Management System</span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={4}>
                                    <div className="mvp-dropdown-item-box-mob" onClick={() => handleMVPClick(true, 'https://rockvale.sa/ropaylanding-mobilos/index.php', 'fintech-banking-solution')}>
                                      <span className="header-icon-disc-last">
                                        <img src={RopayMobile} />
                                      </span>
                                      <span>FinTech Banking Solution</span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Container>
                            </Collapse>
                            {menuItems.map((item, key) => <MenuItem key={key} item={item} depthLevel={0} />)}
                          </div>
                        </Drawer>
                      </Fragment>
                    }
                  </div>
                </Box>
                <Box className="nav-offdesk-tower" sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                  <div className="logo-nav-left logo-nav-left33" >
                    <div className={`${classes.logo} logo-mrgn-right cursor-pointer`} onClick={() => props.history.push('/')} >
                      <img src={mobilosLogo} alt="logo" />
                    </div>
                    <nav>
                      <div className="mvp-button">
                        <div className={`menu-items ${openMvp && 'active'}`}>
                          <a
                            onMouseEnter={() => { setOpenMvp(true); setOpenHireTalent(false); }}
                            onClick={() => setOpenMvp(!openMvp)}
                          >
                            MVP
                          </a>
                        </div>
                      </div>
                      <div onMouseLeave={() => setOpenMvp(false)} className={`mvp-subbox ${openMvp && 'display-block'}`}>
                        <Container>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className="mvp-dropdown-item-box" onClick={() => handleMVPClick(false, 'https://rockvale.sa/vidly-mobilos/index.php', 'ott-streaming-platform')}>
                                <span className="header-icon-disc">
                                  <img src={Vidly} />
                                </span>
                                <span>OTT Streaming Platform.</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className="mvp-dropdown-item-box" onClick={() => handleMVPClick(false, 'https://rockvale.sa/mobilos-tryppk/index.php', 'fleet-management-system')}>
                                <span className="header-icon-disc">
                                  <img src={tryp} />
                                </span>
                                <span>Fleet Management System</span>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className="mvp-dropdown-item-box" onClick={() => handleMVPClick(false, 'https://rockvale.sa/ropaylanding-mobilos/index.php', 'fintech-banking-solution')}>
                                <span className="header-icon-disc-last">
                                  <img src={Ropay} />
                                </span>
                                <span>FinTech Banking Solution</span>
                              </div>
                            </Grid>
                          </Grid>
                        </Container>
                      </div>
                    </nav>
                    <nav>
                      <div className="mvp-button-hire">
                        <div className={`menu-items ${openHireTalent && 'active'}`}>
                          <a
                            onMouseEnter={() => { setOpenHireTalent(true); setOpenMvp(false) }}
                            onClick={() => setOpenHireTalent(!openHireTalent)}
                          >
                            Hire Talent
                          </a>
                        </div>
                      </div>
                      <div onMouseLeave={() => setOpenHireTalent(false)} className={`mvp-subbox-hire ${openHireTalent && 'display-block'}`} >
                        <Container>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className="tect-box-rad">
                                <div className="developmult-icon">
                                  <span className="ser-icon">
                                    <img src={developersIcons} className="img-fluid img-responsive " alt="" />
                                  </span>
                                  <h3 className="card-title">Web Developers</h3>
                                </div>
                                <ul className="menu-font-style" >
                                  <li onClick={() => handleHireTalentClick('java-developers', '6482d5fa8cb990e9bb3889b8')}>Java Developers</li>
                                  <li onClick={() => handleHireTalentClick('php-laravel-developers', '6482d5fa8cb990e9bb3889ba')}>PHP/Laravel Developers</li>
                                  <li onClick={() => handleHireTalentClick('reactjs-vuejs-developers', '6482d5fa8cb990e9bb3889bc')}>React.js/Vue.js Developers</li>
                                  <li onClick={() => handleHireTalentClick('nodejs-developers', '6482d5fa8cb990e9bb3889be')}>Node.js Developers</li>
                                  <li onClick={() => handleHireTalentClick('angular-developers', '6482d5fa8cb990e9bb3889c0')}>Angular Developers</li>
                                </ul>
                              </div>
                              <div className="tect-box-rad">
                                <div className="developmult-icon">
                                  <span className="ser-icon">
                                    <img src={mobileAppDeveloperIcon} className="img-fluid img-responsive " alt="" />
                                  </span>
                                  <h3>Mobile App Developers</h3>
                                </div>
                                <ul className="menu-font-style">
                                  <li onClick={() => handleHireTalentClick('android-developers', '6482d5fa8cb990e9bb3889c2')}>Android Developers</li>
                                  <li onClick={() => handleHireTalentClick('ios-developers', '6482d5fa8cb990e9bb3889c4')}>iOS Developers</li>
                                </ul>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              {/* <div className="tect-box-rad">
                                <div className="developmult-icon">
                                  <span className="ser-icon">
                                    <img src={webDeveloperIcons} className="img-fluid img-responsive " alt="" />
                                  </span>
                                  <h3>Web Developers</h3>
                                </div>
                                <ul className="menu-font-style">
                                  <li onClick={() => props.history.push({ pathname: '/hiring' })}>Android Developers</li>
                                  <li>IOS Developers</li>
                                </ul>
                              </div> */}
                              <div className="tect-box-rad">
                                <div className="developmult-icon">
                                  <span className="ser-icon">
                                    <img src={qualityAssuranceIcon} className="img-fluid img-responsive " alt="" />
                                  </span>
                                  <h3>Quality Assurance</h3>
                                </div>
                                <ul className="menu-font-style">
                                  <li onClick={() => handleHireTalentClick('qa-engineer', '6482d5fa8cb990e9bb3889cc')}>QA Engineer</li>
                                </ul>
                              </div>
                              <div className="tect-box-rad">
                                <div className="developmult-icon">
                                  <span className="ser-icon">
                                    <img src={gameDeveloperIcon} className="img-fluid img-responsive " alt="" />
                                  </span>
                                  <h3>Game Development Team</h3>
                                </div>
                                <ul className="menu-font-style">
                                  <li onClick={() => handleHireTalentClick('game-engineer', '6482f3fa9ca340906a8e98ca')} >Game Engineers(Unity)</li>
                                </ul>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div className="tect-box-rad">
                                <div className="developmult-icon">
                                  <span className="ser-icon">
                                    <img src={creativeDesignIcon} className="img-fluid img-responsive " alt="" />
                                  </span>
                                  <h3>Creatives & Design</h3>
                                </div>
                                <ul className="menu-font-style">
                                  <li onClick={() => handleHireTalentClick('ui-ux-engineer', '6482d5fa8cb990e9bb3889c6')}>UI/UX Engineers</li>
                                  <li onClick={() => handleHireTalentClick('graphic-designer', '6482d5fa8cb990e9bb3889c8')}>Graphic Designer</li>
                                  <li onClick={() => handleHireTalentClick('video-animators', '6482d5fa8cb990e9bb3889ca')}>Video Animators</li>
                                </ul>
                              </div>
                              <div className="tect-box-rad">
                                <div className="developmult-icon">
                                  <span className="ser-icon">
                                    <img src={marketingIcon} className="img-fluid img-responsive " alt="" />
                                  </span>
                                  <h3>Marketing</h3>
                                </div>
                                <ul className="menu-font-style">
                                  <li onClick={() => handleHireTalentClick('social-media-marketing-experts', '6482d5fa8cb990e9bb3889ce')}>Social Media Marketing Experts</li>
                                  <li onClick={() => handleHireTalentClick('seo-experts', '6482d5fa8cb990e9bb3889d0')}>SEO Experts</li>
                                  <li onClick={() => handleHireTalentClick('ppc-media-buying-experts', '6482d5fa8cb990e9bb3889d2')}>PPC/Media Buying Experts</li>
                                  <li onClick={() => handleHireTalentClick('copywriting-content-writers', '6482d5fa8cb990e9bb3889d4')}>Copywriting/Content Writers</li>
                                </ul>
                              </div>
                            </Grid>
                          </Grid>
                        </Container>
                      </div>
                    </nav>
                    <Navbar setOpenMvp={setOpenMvp} setOpenHireTalent={setOpenHireTalent} />
                    {/* <nav>
                      <ul className="menus">
                        <li className="menu-items"><RouterLink to={{ pathname: "/product" }}>MVPs</RouterLink></li>
                        <li className="menu-items"><RouterLink to={{ pathname: "/" }}>Company</RouterLink></li>
                        <li className="menu-items"><RouterLink to={{ pathname: "/" }}>Hire Talent</RouterLink></li>
                      </ul>
                    </nav> */}
                  </div>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                  <div className={`${classes.logoMobile}`} onClick={() => props.history.push('/')}>
                    <img src={mobilosLogo} alt="logo" />
                  </div>
                </Box>
                <Box className="nav-menu" ml="auto" mr={8} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} >
                  <nav>
                    {/* <ul className="menus">
                      <li className="menu-items"><RouterLink to={{ pathname: "/" }}>Hire Talent</RouterLink></li>
                      <li className="menu-items"><RouterLink to={{ pathname: "/" }}> Company</RouterLink></li>
                    </ul> */}
                    <div className="menus">
                      <RouterLink to="/contact-us"> <Button className="hire-pro-btn" >Hire a Pro</Button></RouterLink>
                    </div>
                    {/* <ul className="menus">
                      <li className="menu-items"><RouterLink to={{ pathname: "/" }}> <Button className="hire-pro-btn">Hire a Pro</Button></RouterLink></li>
                    </ul> */}
                  </nav>
                </Box>
                <Box sx={{ display: { md: 'none', lg: 'none' }, marginLeft: 'auto' }}>
                  <Button style={{ minWidth: "24px", padding: 0 }} onClick={toggleDrawer}>
                    <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 1.04167C0 0.46875 0.46875 0 1.04167 0H18.75C19.3229 0 19.7917 0.46875 19.7917 1.04167C19.7917 1.61458 19.3229 2.08333 18.75 2.08333H1.04167C0.46875 2.08333 0 1.61458 0 1.04167ZM18.75 16.6667H1.04167C0.46875 16.6667 0 17.1354 0 17.7083C0 18.2812 0.46875 18.75 1.04167 18.75H18.75C19.3229 18.75 19.7917 18.2812 19.7917 17.7083C19.7917 17.1354 19.3229 16.6667 18.75 16.6667ZM23.9583 8.33333H6.25C5.67708 8.33333 5.20833 8.80208 5.20833 9.375C5.20833 9.94792 5.67708 10.4167 6.25 10.4167H23.9583C24.5312 10.4167 25 9.94792 25 9.375C25 8.80208 24.5312 8.33333 23.9583 8.33333Z" fill="#1A8FE3" />
                    </svg>
                  </Button>
                </Box>
              </div>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );


}

//what is needed at start
const mapStateToProps = ({ }) => {
};
//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    //cmsMessageHandler: () => dispatch(actions.cmsMessageHandler()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
