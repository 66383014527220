import { useState, useEffect, useRef } from "react";
import Dropdown from './Dropdown.js';
import { Link as RouterLink } from "react-router-dom";
import '../scss/dropdown.scss';
import { AnimatedIcon } from "../../../components";

const MenuItems = ({ items, depthlevel }) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };
    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    return (
        <li
            className="menu-items"
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {items.submenu ? (<>
                <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"}
                    onClick={
                        () => setDropdown((prev) => !prev)
                    }>
                    {
                        items.title
                    }
                </button> <Dropdown depthlevel={
                    depthlevel
                } submenus={
                    items.submenu
                } dropdown={
                    dropdown
                }
                /></>
            ) : 
            (<RouterLink to={{ pathname: items.url, state: { id: items.id ? items.id : '' } }}  >
                {items.icon && <span className="animated-icon"><AnimatedIcon icon={items.icon} height={27} width={27} /></span>}
                {items.title}
            </RouterLink>
            )
            } </li>
    );

};

export default MenuItems;