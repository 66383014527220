// Users Action Types
export const GET_USERS_LIST_START = "GET_USERS_LIST_START";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";
export const SET_USER_SERVICES_LIST = "USER_SERVICES_LIST";
export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_ERROR";

export const ADD_NEW_USER_START = "ADD_NEW_USER_START";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const SEND_OTP_START = "SEND_OTP_START";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_ERROR";

export const ADD_OTP_START = "ADD_OTP_START";
export const ADD_OTP_SUCCESS = "ADD_OTP_SUCCESS";
export const ADD_OTP_FAILURE = "ADD_OTP_ERROR";

export const SEND_QUERY_START = "SEND_QUERY_START";
export const SEND_QUERY_SUCCESS = "SEND_QUERY_SUCCESS";
export const SEND_QUERY_FAILURE = "SEND_QUERY_ERROR";

export const GET_CONFIG_START = "GET_CONFIG_START";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";

export const GET_VIEWS_START = "GET_VIEWS_START";
// export const GET_VIEWS_SUCCESS = "GET_VIEWS_SUCCESS";
export const GET_VIEWS_FAILURE = "GET_VIEWS_FAILURE";

// Auction Action Types
export const GET_AUCTION_LIST_START = "GET_AUCTION_LIST_START";
export const GET_AUCTION_LIST_SUCCESS = "GET_AUCTION_LIST_SUCCESS";
export const GET_AUCTION_LIST_FAILURE = "GET_AUCTION_LIST_FAILURE";
export const GET_AUCTION_DATA_START = "GET_AUCTION_DATA_START";
export const GET_AUCTION_DATA_SUCCESS = "GET_AUCTION_DATA_SUCCESS";
export const GET_AUCTION_DATA_FAILURE = "GET_AUCTION_DATA_ERROR";

export const GET_PAYMENT_DATA_START = "GET_PAYMENT_DATA_START";
export const GET_PAYMENT_DATA_SUCCESS = "GET_PAYMENT_DATA_SUCCESS";
export const GET_PAYMENT_DATA_FAILURE = "GET_PAYMENT_DATA_ERROR";

export const CREATE_AUCTION_START = "CREATE_AUCTION_START";
export const CREATE_AUCTION_SUCCESS = "CREATE_AUCTION_SUCCESS";
export const CREATE_AUCTION_FAILURE = "CREATE_AUCTION_ERROR";

export const MESSAGE_HANDLER = "MESSAGE_HANDLER"; 

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const RESET_PASSWORD_EMAIL_SENT_START = "RESET_PASSWORD_EMAIL_SENT_START";
export const RESET_PASSWORD_EMAIL_SENT_SUCCESS = "RESET_PASSWORD_EMAIL_SENT_SUCCESS";
export const RESET_PASSWORD_EMAIL_SENT_FAILURE = "RESET_PASSWORD_EMAIL_SENT_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const ERROR_HANDLER_SUCCESS = "ERROR_HANDLER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_ERROR_HANDLER_SUCCESS = "DASHBOARD_ERROR_HANDLER_SUCCESS";

// GENERAL

export const GET_DEPARTMENTS_START = "GET_DEPARTMENTS_START";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";

export const GET_TECHNOLOGIES_START = "GET_TECHNOLOGIES_START";
export const GET_TECHNOLOGIES_SUCCESS = "GET_TECHNOLOGIES_SUCCESS";
export const GET_TECHNOLOGIES_FAILURE = "GET_TECHNOLOGIES_FAILURE";

export const GET_EMPLOYEE_START = "GET_EMPLOYEE_START";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAILURE = "GET_EMPLOYEE_FAILURE";

export const SUBMIT_CONTACT_US_START = "SUBMIT_CONTACT_US_START";
export const SUBMIT_CONTACT_US_SUCCESS = "SUBMIT_CONTACT_US_SUCCESS";
export const SUBMIT_CONTACT_US_FAILURE = "SUBMIT_CONTACT_US_FAILURE";

export const GET_TECHNOLOGY_BY_ID_START = "GET_TECHNOLOGY_BY_ID_START";
export const GET_TECHNOLOGY_BY_ID_SUCCESS = "GET_TECHNOLOGY_BY_ID_SUCCESS";
export const GET_TECHNOLOGY_BY_ID_FAILURE = "GET_TECHNOLOGY_BY_ID_FAILURE";