import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import { put, takeEvery, all, fork } from "redux-saga/effects";
import GeneralService from '../../services/general.service'

/** Contact Us */
function* getDepartments(action) {
  try {
    if (action) {
      const response = yield GeneralService.getDepartments();
      if (response?.data) {
        yield put(actions.getDepartmentsSuccess(response?.data));
      } else {
        yield put(actions.getDepartmentsFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getDepartmentsFailure(error));
  }
}
function* getDepartmentsSaga() {
  yield takeEvery(actionsTypes.GET_DEPARTMENTS_START, getDepartments);
}

function* getTechnologies(action) {
  try {
    if (action) {
      const response = yield GeneralService.getTechnologies();
      if (response?.data) {
        yield put(actions.getTechnologiesSuccess(response?.data));
      } else {
        yield put(actions.getTechnologiesFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getTechnologiesFailure(error));
  }
}
function* getTechnologiesSaga() {
  yield takeEvery(actionsTypes.GET_TECHNOLOGIES_START, getTechnologies);
}

function* getEmployee(action) {
  try {
    if (action) {
      const response = yield GeneralService.getEmployee();
      if (response?.data) {
        yield put(actions.getEmployeeSuccess(response?.data));
      } else {
        yield put(actions.getEmployeeFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getEmployeeFailure(error));
  }
}
function* getEmployeeSaga() {
  yield takeEvery(actionsTypes.GET_EMPLOYEE_START, getEmployee);
}

function* submitContactUs(action) { /** News Details */
  try {
    if (action) {
      const payload = action.payload;
      const response = yield GeneralService.contactUs(payload);
      if (response?.data.statusCode == 201) {
        yield put(actions.submitContactUsSuccess(response?.data.message));
      } else {
        yield put(actions.submitContactUsFailure(response?.data.message));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.submitContactUsFailure(error));
  }
}
function* contactUsSaga() {
  yield takeEvery(actionsTypes.SUBMIT_CONTACT_US_START, submitContactUs);
}

// Technology
function* getTechnologyById(action) {
  try {
    if (action.payload) {
      const response = yield GeneralService.getTechById(action.payload);
      if (response?.data) {
        yield put(actions.getTechnologyByIdSuccess(response?.data));
      } else {
        yield put(actions.getTechnologyByIdFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getTechnologyByIdFailure(error));
  }
}
function* getTechnologyByIdSaga() {
  yield takeEvery(actionsTypes.GET_TECHNOLOGY_BY_ID_START, getTechnologyById);
}

export function* generalSaga() {
  yield all([
    fork(getDepartmentsSaga),
    fork(getEmployeeSaga),
    fork(contactUsSaga),
    fork(getTechnologyByIdSaga),
    fork(getTechnologiesSaga),
  ]);
}
