import React, { Suspense, useState, useEffect} from "react"; 
import Header from '../../container/frontend/layout/header';
import Footer from '../../container/frontend/layout/footer';
import {Loader} from "../../components";
import '../../assets/frontend.scss';
import * as actions from "../../store/actions";
import { connect } from "react-redux";

const FrontLayout = (props) => { 

  useEffect(() => {
    props.getDepartmentsStart();
    props.getTechnologiesStart();
  }, []);

  return (
    <div>
      <Header/>
        <div>
          <Suspense fallback={<Loader/>}>
            {props.children}
          </Suspense>
        </div>
      <Footer/>
    </div>
  );
};

//what is needed at start
const mapStateToProps = ({ generalReducer }) => {
  const { departmentsData } = generalReducer;
  return { departmentsData };
};
//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    getDepartmentsStart: () => dispatch(actions.getDepartments()),
    getTechnologiesStart: () => dispatch(actions.getTechnologies()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontLayout);