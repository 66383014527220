import {all} from "redux-saga/effects";
import {authSaga} from "./authSagas";
import {dashboardSaga} from "./dashboardSagas";
import {userSaga} from "./userSagas";
import {generalSaga} from "./generalSagas";
export function* rootSaga(getState) {
  yield all([
    authSaga(),
    dashboardSaga(),
    userSaga(),
    generalSaga(),
  ]);
}
