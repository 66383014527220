import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  contactLoading: false,
  data: null,
  departmentsData: null,
  employeeData: null,
  allTechnologyData: null,
  techData: null,
  error: null,
  success: null,
  successContactUs: null,
};
export const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    /** Home */

    case actionTypes.GET_EMPLOYEE_START:
      return { ...state, loading: true, error: null, success: null };

    case actionTypes.GET_EMPLOYEE_SUCCESS:
      return { ...state, loading: false, error: null, success: null, employeeData: action.payload };

    case actionTypes.GET_EMPLOYEE_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null };

    case actionTypes.SUBMIT_CONTACT_US_START:
      return { ...state, contactLoading: true, error: null, successContactUs: null };

    case actionTypes.SUBMIT_CONTACT_US_SUCCESS:
      return { ...state, contactLoading: false, error: null, successContactUs: action.payload };

    case actionTypes.SUBMIT_CONTACT_US_FAILURE:
      return { ...state, contactLoading: false, error: action.payload, successContactUs: null };


    // TECHNOLOGY
    case actionTypes.GET_TECHNOLOGY_BY_ID_START:
      return { ...state, loading: true, error: null, success: null };
    case actionTypes.GET_TECHNOLOGY_BY_ID_SUCCESS:
      return { ...state, loading: false, error: null, success: null, techData: action.payload };
    case actionTypes.GET_TECHNOLOGY_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null };
    
    case actionTypes.GET_TECHNOLOGIES_START:
      return { ...state, loading: true, error: null, success: null };
    case actionTypes.GET_TECHNOLOGIES_SUCCESS:
      return { ...state, loading: false, error: null, success: null, allTechnologyData: action.payload };
    case actionTypes.GET_TECHNOLOGIES_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null };

    // Department
    case actionTypes.GET_DEPARTMENTS_START:
      return { ...state, loading: true, error: null, success: null };
    case actionTypes.GET_DEPARTMENTS_SUCCESS:
      return { ...state, loading: false, error: null, success: null, departmentsData: action.payload };
    case actionTypes.GET_DEPARTMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null };

    case actionTypes.GET_EMPLOYEE_START:
      return { ...state, loading: true, error: null, success: null };

    case actionTypes.MESSAGE_HANDLER:
      return { ...state, loading: false, contactLoading: false, error: null, success: null, successContactUs: action.payload };

    default:
      return {
        loading: false,
        contactLoading: false,
        error: null,
        success: null,
        successContactUs: null,
        techData: null,
        data: null,
      };
  }
};
